<template>
    <div class='sign-up-header'>
            <img src='../../public/Invrs-Logo-Green-Blue.png' width='250' style="cursor: pointer;" @click="goHome()">
             <Button label="Sign In" class="p-button-text sign-in-button" @click="goToLoginPage()" />
    </div>

    <div class='sign-in-container'>
        <span class='sign-in-title'>Email Confirmed.</span>
        <span >Thank you for confirming your email.  Enjoy!</span>
                
    </div>
    
</template>

<script>
import {HOME} from '../common/config';

export default {
    name: 'EmailConfirmed',

    components: {
        
    },
    data () {
        return {
           
        }
    },
    mounted() {
      
    },

    methods: {
       
         goHome() {
            window.location.href = HOME;
        },
        goToLoginPage() {
            this.$router.push({path:'/login'});
        },
       
        
        
    }
}
</script>

<style scoped>
* {
    font-family: "Trebuchet MS", "Verdana";
}

.sign-up-header {
    display: flex;
    align-items: center;
    padding: 10px 35px 30px;
}
.p-button-text {
    font-weight: bold;
    color: #32364e;
}
.p-button-text:enabled:hover {
    background: none;
    color: #33CC99;
}
.p-button-text:enabled:focus {
    box-shadow: none;
    border: none;
}
.forgot-password-button {
    margin-right: auto;
    font-size: 22px;
    padding: 9px 0px;
    border: none;
}
.sign-up-button {
    margin-left: auto;
    font-size: 25px;
    padding: 9px 15px;
}

.sign-in-container {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: 200px auto auto auto;
}

.sign-in-title {
    font-size: 60px;
    color: #32364e;
    padding-bottom: 0.7em;
}

.input-container {
    display: flex;
    padding-bottom: 35px;
}

.p-inputtext {
    width: 100%;
    font-size: 20px;
    border-radius: 15px;
    border: 2px solid #BFBFBF;
    background-color: #F2F4FA;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
}
.p-inputtext:enabled:focus {
    box-shadow: none;
    border-color: #33CC99;
}
.p-float-label {
    width: 50%;
}
.input-label {
    font-size: 20px;
    padding-left: 20px;
    line-height: 0.8;
}

.button-container {
    display: flex;
}

.sign-in-button {
    margin-left: auto;
    width: 150px;
    border-radius: 8px;
    background-color: #33CC99;
    font-size: 25px;
    padding: 9px 15px;
    color: white;
    border: 1px solid #33CC99;
    justify-content: center;
}
.sign-in:enabled:focus {
    box-shadow: none;
    border-color: #33CC99;
}

</style>
